import React from "react";
import styled from "styled-components";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import { H3, P1 } from "app/components/Typography";
import { Playlist } from "app/components/Icon";
import { Link } from "react-router-dom";

const StyledIcon = styled.i`
  color: ${({ theme }) => theme.colors.monochrome[3]};
`;

export function EmptyState() {
  return (
    <Flex
      mt={{ _: 3, md: 6 }}
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Flex flexDirection="column" alignItems="center" textAlign="center">
        <Div mb={4}>
          <StyledIcon as={Playlist} width="52.5px" height="35px" />
        </Div>
        <H3 fontWeight="bold" color="monochrome.6" mb={2}>
          Follow your favorite playlists
        </H3>
        <P1 color="monochrome.5" textTransform="none">
          Browse some of our{" "}
          <Link to="/playlists/steezy">STEEZY Playlists</Link> to follow!
        </P1>
      </Flex>
    </Flex>
  );
}
